import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="153" height="150" viewBox="0 0 1079 1056" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 948.55V797.38H36.49C46.91 797.38 54.6 797.95 59.58 799.1C64.56 800.25 68.79 802.13 72.29 804.76C76.77 808.13 80.25 812.54 82.72 818C85.19 823.46 86.43 829.48 86.43 836.08C86.43 844.11 84.76 850.82 81.43 856.21C78.1 861.6 73.26 865.36 66.91 867.48C75.72 868.9 82.66 873.04 87.72 879.89C92.78 886.74 95.31 895.39 95.31 905.82C95.31 911.92 94.26 917.7 92.16 923.18C90.06 928.66 87.08 933.32 83.22 937.15C79.15 941.33 74.11 944.27 68.08 945.98C62.05 947.69 51.92 948.55 37.7 948.55H0ZM22.41 859.64H31.83C43.56 859.64 51.86 858.09 56.73 854.98C61.6 851.87 64.03 846.64 64.03 839.28C64.03 831.26 61.79 825.62 57.3 822.38C52.81 819.14 44.33 817.51 31.84 817.51H22.42V859.64H22.41ZM22.41 928.41H30.89C39.51 928.41 45.96 928.06 50.24 927.37C54.52 926.68 57.9 925.46 60.38 923.72C63.41 921.71 65.76 919 67.42 915.6C69.08 912.2 69.91 908.45 69.91 904.35C69.91 899.49 68.91 895.26 66.91 891.65C64.91 888.04 62.05 885.23 58.32 883.21C55.97 882.03 53.3 881.16 50.3 880.61C47.3 880.06 43.31 879.78 38.35 879.78H30.9H22.42V928.41H22.41Z" fill="url(#paint0_linear_1_3)" />
        <path d="M247.08 948.55L229.92 909.62H169.97L152.5 948.55H126.68L199.89 791.38L273 948.55H247.08ZM179 889.49H221.04L205.71 854.72C204.82 852.5 203.9 849.96 202.94 847.1C201.98 844.24 201.02 841.06 200.07 837.55C199.25 840.85 198.37 843.94 197.45 846.79C196.53 849.65 195.59 852.28 194.63 854.69L179 889.49Z" fill="url(#paint1_linear_1_3)" />
        <path d="M312.63 948.55V797.38H335.04V928.42H392.14V948.56H312.63V948.55Z" fill="url(#paint2_linear_1_3)" />
        <path d="M437.44 948.55V797.38H521.8V817.52H459.85V857.38H521.81V877.52H459.85V948.56H437.44V948.55Z" fill="url(#paint3_linear_1_3)" />
        <path d="M573.2 797.38H595.61V889.18C595.61 902.81 598.76 913.05 605.07 919.9C611.38 926.75 620.8 930.17 633.35 930.17C645.83 930.17 655.22 926.75 661.53 919.9C667.84 913.05 670.99 902.81 670.99 889.18V797.38H693.4V891.45C693.4 911.9 688.41 927.2 678.44 937.35C668.47 947.5 653.44 952.58 633.35 952.58C613.17 952.58 598.1 947.52 588.14 937.4C578.18 927.28 573.2 911.96 573.2 891.45V797.38Z" fill="url(#paint4_linear_1_3)" />
        <path d="M756.57 948.55V790.32L852.81 883.6L861.09 891.62C863.57 894.74 866.18 898.26 868.92 902.18V797.38H891.33V954.44L793.57 860.55C790.95 857.98 788.41 855.26 785.96 852.41C783.51 849.56 781.18 846.56 778.98 843.43V948.55H756.57Z" fill="url(#paint5_linear_1_3)" />
        <path d="M951.81 948.55V797.38H984.42C1005.62 797.38 1020.54 798.47 1029.18 800.64C1037.82 802.81 1045.39 806.45 1051.89 811.55C1060.49 818.24 1067.01 826.82 1071.45 837.3C1075.89 847.78 1078.11 859.74 1078.11 873.18C1078.11 886.58 1075.89 898.46 1071.45 908.81C1067.01 919.16 1060.49 927.7 1051.89 934.43C1045.39 939.52 1037.99 943.14 1029.69 945.31C1021.39 947.48 1008.47 948.56 990.93 948.56H984.43H951.81V948.55ZM974.22 928.41H994.9C1006.27 928.41 1014.8 927.63 1020.49 926.06C1026.18 924.49 1031.12 921.85 1035.33 918.14C1041.05 913.07 1045.34 906.77 1048.2 899.25C1051.06 891.72 1052.49 882.96 1052.49 872.97C1052.49 862.98 1051.06 854.22 1048.2 846.69C1045.34 839.16 1041.05 832.86 1035.33 827.79C1031.06 824.08 1025.94 821.44 1019.97 819.87C1014.01 818.3 1004.69 817.51 992 817.51H983.11H974.22V928.41Z" fill="url(#paint6_linear_1_3)" />
        <path d="M42.68 1053.02L36.6 1039.22H15.35L9.16 1053.02H0.0100098L25.96 997.31L51.87 1053.02H42.68ZM18.55 1032.09H33.45L28.02 1019.77C27.7 1018.98 27.38 1018.08 27.04 1017.07C26.7 1016.06 26.36 1014.93 26.02 1013.68C25.73 1014.85 25.42 1015.94 25.09 1016.96C24.76 1017.97 24.43 1018.91 24.09 1019.76L18.55 1032.09Z" fill="url(#paint7_linear_1_3)" />
        <path d="M76.4099 1053.02V999.44H89.3399C93.0299 999.44 95.7599 999.64 97.5199 1000.05C99.2799 1000.46 100.79 1001.12 102.02 1002.05C103.61 1003.24 104.84 1004.81 105.72 1006.74C106.6 1008.67 107.03 1010.81 107.03 1013.15C107.03 1016 106.44 1018.37 105.26 1020.28C104.08 1022.19 102.36 1023.52 100.11 1024.28C103.23 1024.78 105.69 1026.25 107.49 1028.68C109.28 1031.11 110.18 1034.17 110.18 1037.87C110.18 1040.03 109.81 1042.08 109.06 1044.02C108.32 1045.96 107.26 1047.61 105.89 1048.97C104.45 1050.45 102.66 1051.49 100.52 1052.1C98.3799 1052.71 94.7899 1053.01 89.7499 1053.01H76.4099V1053.02ZM84.3499 1021.51H87.6899C91.8499 1021.51 94.7899 1020.96 96.5199 1019.86C98.2399 1018.76 99.1099 1016.9 99.1099 1014.3C99.1099 1011.46 98.3099 1009.46 96.7199 1008.31C95.1299 1007.16 92.1199 1006.59 87.6899 1006.59H84.3499V1021.51ZM84.3499 1045.89H87.3599C90.4199 1045.89 92.6999 1045.77 94.2199 1045.52C95.7399 1045.27 96.9299 1044.84 97.8099 1044.23C98.8899 1043.52 99.7199 1042.56 100.3 1041.35C100.89 1040.14 101.18 1038.82 101.18 1037.36C101.18 1035.64 100.83 1034.14 100.12 1032.86C99.4099 1031.58 98.4 1030.58 97.08 1029.87C96.25 1029.45 95.2999 1029.14 94.2399 1028.95C93.1799 1028.76 91.7599 1028.65 89.9999 1028.65H87.3599H84.3499V1045.89Z" fill="url(#paint8_linear_1_3)" />
        <path d="M181.19 1053.02V999.44H189.13V1045.88H209.37V1053.02H181.19Z" fill="url(#paint9_linear_1_3)" />
        <path d="M288.18 1026.34C288.18 1030.15 287.47 1033.75 286.04 1037.14C284.61 1040.53 282.53 1043.56 279.8 1046.22C277.04 1048.88 273.93 1050.92 270.47 1052.33C267.01 1053.74 263.37 1054.45 259.56 1054.45C255.7 1054.45 252.03 1053.74 248.53 1052.31C245.03 1050.88 241.94 1048.85 239.25 1046.22C236.52 1043.56 234.44 1040.55 233.03 1037.18C231.61 1033.81 230.91 1030.2 230.91 1026.35C230.91 1022.52 231.62 1018.91 233.03 1015.52C234.44 1012.13 236.52 1009.09 239.25 1006.41C241.98 1003.75 245.08 1001.72 248.55 1000.33C252.01 998.94 255.69 998.24 259.57 998.24C263.43 998.24 267.08 998.94 270.53 1000.33C273.98 1001.72 277.07 1003.75 279.81 1006.41C282.54 1009.12 284.62 1012.17 286.05 1015.58C287.47 1018.97 288.18 1022.56 288.18 1026.34ZM279.11 1026.34C279.11 1023.63 278.62 1021.06 277.63 1018.62C276.64 1016.18 275.22 1014.01 273.37 1012.11C271.56 1010.23 269.47 1008.79 267.09 1007.77C264.71 1006.76 262.2 1006.25 259.57 1006.25C256.93 1006.25 254.43 1006.75 252.05 1007.75C249.67 1008.75 247.55 1010.2 245.7 1012.11C243.84 1013.99 242.43 1016.15 241.45 1018.59C240.47 1021.03 239.99 1023.62 239.99 1026.35C239.99 1029.06 240.48 1031.62 241.45 1034.04C242.43 1036.46 243.84 1038.61 245.7 1040.52C247.55 1042.42 249.66 1043.88 252.03 1044.87C254.4 1045.87 256.91 1046.37 259.57 1046.37C262.18 1046.37 264.66 1045.87 267.02 1044.87C269.38 1043.87 271.49 1042.42 273.37 1040.52C275.22 1038.62 276.65 1036.45 277.63 1034.02C278.61 1031.58 279.11 1029.03 279.11 1026.34Z" fill="url(#paint10_linear_1_3)" />
        <path d="M314.59 1042.26L321.54 1039.01C322.2 1041.42 323.41 1043.26 325.16 1044.53C326.92 1045.8 329.16 1046.43 331.89 1046.43C334.48 1046.43 336.53 1045.71 338.06 1044.27C339.58 1042.83 340.35 1040.9 340.35 1038.49C340.35 1035.34 337.74 1032.54 332.52 1030.07C331.79 1029.7 331.23 1029.43 330.84 1029.25C324.93 1026.37 321 1023.77 319.03 1021.44C317.07 1019.11 316.08 1016.26 316.08 1012.9C316.08 1008.53 317.56 1005 320.53 1002.29C323.49 999.58 327.38 998.23 332.19 998.23C336.14 998.23 339.45 998.96 342.11 1000.43C344.77 1001.89 346.61 1004 347.64 1006.74L340.83 1010.29C339.76 1008.59 338.58 1007.35 337.3 1006.56C336.02 1005.77 334.55 1005.38 332.89 1005.38C330.55 1005.38 328.69 1006 327.31 1007.24C325.93 1008.48 325.24 1010.14 325.24 1012.22C325.3 1015.49 328.36 1018.51 334.42 1021.29L335.52 1021.79C340.78 1024.25 344.39 1026.66 346.35 1029.01C348.31 1031.36 349.29 1034.29 349.29 1037.81C349.29 1042.91 347.69 1046.96 344.5 1049.96C341.3 1052.96 336.98 1054.46 331.54 1054.46C326.98 1054.46 323.24 1053.41 320.32 1051.3C317.4 1049.19 315.49 1046.18 314.59 1042.26Z" fill="url(#paint11_linear_1_3)" />
        <path d="M375.22 1042.26L382.17 1039.01C382.83 1041.42 384.04 1043.26 385.79 1044.53C387.55 1045.8 389.79 1046.43 392.52 1046.43C395.11 1046.43 397.16 1045.71 398.69 1044.27C400.21 1042.83 400.98 1040.9 400.98 1038.49C400.98 1035.34 398.37 1032.54 393.15 1030.07C392.42 1029.7 391.86 1029.43 391.47 1029.25C385.56 1026.37 381.63 1023.77 379.66 1021.44C377.7 1019.11 376.71 1016.26 376.71 1012.9C376.71 1008.53 378.19 1005 381.16 1002.29C384.12 999.58 388.01 998.23 392.82 998.23C396.77 998.23 400.08 998.96 402.74 1000.43C405.4 1001.89 407.24 1004 408.27 1006.74L401.46 1010.29C400.39 1008.59 399.21 1007.35 397.93 1006.56C396.65 1005.77 395.18 1005.38 393.52 1005.38C391.18 1005.38 389.32 1006 387.94 1007.24C386.56 1008.48 385.87 1010.14 385.87 1012.22C385.93 1015.49 388.99 1018.51 395.05 1021.29L396.15 1021.79C401.41 1024.25 405.02 1026.66 406.98 1029.01C408.94 1031.36 409.92 1034.29 409.92 1037.81C409.92 1042.91 408.32 1046.96 405.13 1049.96C401.93 1052.96 397.61 1054.46 392.17 1054.46C387.61 1054.46 383.87 1053.41 380.95 1051.3C378.03 1049.19 376.12 1046.18 375.22 1042.26Z" fill="url(#paint12_linear_1_3)" />
        <path d="M481.02 1053.02V996.94L515.13 1030L518.06 1032.84C518.94 1033.95 519.87 1035.2 520.84 1036.58V999.43H528.78V1055.1L494.13 1021.82C493.2 1020.91 492.3 1019.95 491.43 1018.94C490.56 1017.93 489.74 1016.87 488.96 1015.76V1053.02H481.02Z" fill="url(#paint13_linear_1_3)" />
        <path d="M596.11 1053.02L590.03 1039.22H568.78L562.59 1053.02H553.44L579.39 997.31L605.3 1053.02H596.11ZM571.98 1032.09H586.88L581.45 1019.77C581.13 1018.98 580.81 1018.08 580.47 1017.07C580.13 1016.06 579.79 1014.93 579.45 1013.68C579.16 1014.85 578.85 1015.94 578.52 1016.96C578.19 1017.97 577.86 1018.91 577.52 1019.76L571.98 1032.09Z" fill="url(#paint14_linear_1_3)" />
        <path d="M629.83 1053.02V999.44H637.77V1019.86H664.16V999.44H672.1V1053.02H664.16V1027H637.77V1053.02H629.83Z" fill="url(#paint15_linear_1_3)" />
        <path d="M705.54 1053.02V999.44H713.48V1053.02H705.54Z" fill="url(#paint16_linear_1_3)" />
        <path d="M788.36 1053.02V999.44H796.3V1019.86H822.69V999.44H830.63V1053.02H822.69V1027H796.3V1053.02H788.36Z" fill="url(#paint17_linear_1_3)" />
        <path d="M918.85 1026.34C918.85 1030.15 918.14 1033.75 916.71 1037.14C915.28 1040.53 913.2 1043.56 910.47 1046.22C907.71 1048.88 904.6 1050.92 901.14 1052.33C897.67 1053.74 894.04 1054.45 890.23 1054.45C886.37 1054.45 882.7 1053.74 879.2 1052.31C875.7 1050.88 872.61 1048.85 869.92 1046.22C867.19 1043.56 865.11 1040.55 863.7 1037.18C862.29 1033.81 861.58 1030.2 861.58 1026.35C861.58 1022.52 862.29 1018.91 863.7 1015.52C865.11 1012.13 867.19 1009.09 869.92 1006.41C872.65 1003.75 875.75 1001.72 879.22 1000.33C882.68 998.94 886.36 998.24 890.24 998.24C894.1 998.24 897.75 998.94 901.2 1000.33C904.65 1001.72 907.74 1003.75 910.48 1006.41C913.21 1009.12 915.29 1012.17 916.72 1015.58C918.14 1018.97 918.85 1022.56 918.85 1026.34ZM909.78 1026.34C909.78 1023.63 909.29 1021.06 908.3 1018.62C907.31 1016.18 905.89 1014.01 904.04 1012.11C902.23 1010.23 900.14 1008.79 897.76 1007.77C895.38 1006.76 892.87 1006.25 890.24 1006.25C887.6 1006.25 885.1 1006.75 882.72 1007.75C880.34 1008.75 878.22 1010.2 876.37 1012.11C874.52 1013.99 873.1 1016.15 872.13 1018.59C871.15 1021.03 870.66 1023.62 870.66 1026.35C870.66 1029.06 871.15 1031.62 872.13 1034.04C873.11 1036.46 874.52 1038.61 876.37 1040.52C878.22 1042.42 880.34 1043.88 882.7 1044.87C885.07 1045.87 887.58 1046.37 890.24 1046.37C892.85 1046.37 895.33 1045.87 897.69 1044.87C900.04 1043.87 902.16 1042.42 904.04 1040.52C905.89 1038.62 907.32 1036.45 908.3 1034.02C909.28 1031.58 909.78 1029.03 909.78 1026.34Z" fill="url(#paint18_linear_1_3)" />
        <path d="M977.47 1025.58H998.7C998.7 1025.94 998.71 1026.43 998.74 1027.07C998.81 1028.75 998.85 1029.89 998.85 1030.48C998.85 1038.07 996.74 1043.96 992.51 1048.16C988.29 1052.36 982.34 1054.46 974.65 1054.46C970.26 1054.46 966.34 1053.81 962.9 1052.52C959.46 1051.23 956.45 1049.27 953.86 1046.66C951.32 1044.07 949.38 1041.03 948.02 1037.54C946.67 1034.05 945.99 1030.31 945.99 1026.33C945.99 1022.28 946.68 1018.55 948.06 1015.14C949.44 1011.73 951.48 1008.72 954.19 1006.11C956.8 1003.55 959.86 1001.6 963.38 1000.26C966.89 998.92 970.65 998.25 974.65 998.25C978.82 998.25 982.55 999.02 985.83 1000.56C989.12 1002.1 992.05 1004.45 994.63 1007.62L988.52 1012.41C986.71 1010.31 984.68 1008.75 982.41 1007.73C980.14 1006.7 977.6 1006.19 974.8 1006.19C968.99 1006.19 964.25 1008.05 960.58 1011.77C956.91 1015.49 955.07 1020.32 955.07 1026.27C955.07 1032.52 956.91 1037.6 960.58 1041.49C964.25 1045.39 969.02 1047.33 974.87 1047.33C979.85 1047.33 983.76 1046.1 986.62 1043.63C989.48 1041.16 990.9 1037.8 990.9 1033.54V1032.72H977.47V1025.58Z" fill="url(#paint19_linear_1_3)" />
        <path d="M1063.42 1053.02L1057.34 1039.22H1036.09L1029.9 1053.02H1020.75L1046.7 997.31L1072.61 1053.02H1063.42ZM1039.29 1032.09H1054.19L1048.76 1019.77C1048.44 1018.98 1048.12 1018.08 1047.78 1017.07C1047.44 1016.06 1047.1 1014.93 1046.76 1013.68C1046.47 1014.85 1046.16 1015.94 1045.83 1016.96C1045.5 1017.97 1045.17 1018.91 1044.83 1019.76L1039.29 1032.09Z" fill="url(#paint20_linear_1_3)" />
        <path d="M504.31 119.19L459.75 62.54L683.69 51.93C652.73 19.93 609.36 0 561.32 0H268.87V304.4L504.31 119.19Z" fill="url(#paint21_linear_1_3)" />
        <path d="M809.23 521.46C809.23 608.78 754.29 683.25 677.13 712.29L550.96 551.9L684.85 446.6L603.41 343.06L469.53 448.4L404.28 365.45L585.74 222.71L614.73 259.54V259.57L634.34 284.49L697.7 68.29C708.34 82.5 716.83 98.44 722.64 115.61C728.45 132.78 731.59 151.16 731.59 170.3C731.59 235.55 694.88 292.19 641.03 320.78C688.83 329.2 730.87 354.3 760.96 389.8C791.08 425.29 809.23 471.25 809.23 521.46Z" fill="url(#paint22_radial_1_3)" />
        <path d="M268.87 406.35V725.32H519.78L268.87 406.35Z" fill="url(#paint23_linear_1_3)" />
        <path opacity="0.31" d="M430.38 398.63L404.28 365.45L585.74 222.71L430.38 398.63Z" fill="black" />
        <defs>
          <linearGradient id="paint0_linear_1_3" x1="47.6587" y1="795.034" x2="47.6587" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint1_linear_1_3" x1="199.838" y1="795.034" x2="199.838" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint2_linear_1_3" x1="352.381" y1="795.034" x2="352.381" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint3_linear_1_3" x1="479.624" y1="795.034" x2="479.624" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint4_linear_1_3" x1="633.301" y1="795.034" x2="633.301" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint5_linear_1_3" x1="823.946" y1="795.034" x2="823.946" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint6_linear_1_3" x1="1014.95" y1="795.034" x2="1014.95" y2="931.68" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint7_linear_1_3" x1="25.9345" y1="1231.61" x2="25.9345" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint8_linear_1_3" x1="93.2956" y1="1231.61" x2="93.2956" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint9_linear_1_3" x1="195.277" y1="1231.61" x2="195.277" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint10_linear_1_3" x1="259.544" y1="1231.61" x2="259.544" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint11_linear_1_3" x1="331.938" y1="1231.61" x2="331.938" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint12_linear_1_3" x1="392.563" y1="1231.61" x2="392.563" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint13_linear_1_3" x1="504.903" y1="1231.61" x2="504.903" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint14_linear_1_3" x1="579.363" y1="1231.61" x2="579.363" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint15_linear_1_3" x1="650.969" y1="1231.61" x2="650.969" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint16_linear_1_3" x1="709.51" y1="1231.61" x2="709.51" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint17_linear_1_3" x1="809.497" y1="1231.61" x2="809.497" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint18_linear_1_3" x1="890.215" y1="1231.61" x2="890.215" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint19_linear_1_3" x1="972.417" y1="1231.61" x2="972.417" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint20_linear_1_3" x1="1046.68" y1="1231.61" x2="1046.68" y2="1161.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <linearGradient id="paint21_linear_1_3" x1="441.182" y1="55.0765" x2="207.497" y2="248.908" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
          <radialGradient id="paint22_radial_1_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(591.667 309.098) scale(243.108 243.107)">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </radialGradient>
          <linearGradient id="paint23_linear_1_3" x1="274.828" y1="517.686" x2="419.749" y2="769.486" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0092FF" />
            <stop offset="1" stopColor="#084B9A" />
          </linearGradient>
        </defs>
      </svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
