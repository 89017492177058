// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  category: getIcon('ic_category'),
  information: getIcon('ic_info'),
  video: getIcon('ic_video'),
  subscription: getIcon('ic_category'),
  business: getIcon('ic_business'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  settings: getIcon('ic_settings'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          // { title: 'create', path: PATH_DASHBOARD.user.newUser },
          // { title: 'edit', path: PATH_DASHBOARD.user.edit },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },
      // {
      //   title: 'settings',
      //   path: PATH_DASHBOARD.settings.root,
      //   icon: ICONS.settings,
      //   children: [
      //     { title: 'upstox', path: PATH_DASHBOARD.settings.upstox },
      //   ],
      // },
      // MANAGEMENT : Business
      // {
      //   title: 'business',
      //   path: PATH_DASHBOARD.business.root,
      //   icon: ICONS.business,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.business.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : Category
      // {
      //   title: 'category',
      //   path: PATH_DASHBOARD.category.root,
      //   icon: ICONS.category,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.category.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : Video
      // {
      //   title: 'video',
      //   path: PATH_DASHBOARD.video.root,
      //   icon: ICONS.video,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.video.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : Subscription
      // {
      //   title: 'Subscription',
      //   path: PATH_DASHBOARD.subscription.root,
      //   icon: ICONS.subscription,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'active', path: PATH_DASHBOARD.subscription.list },
      //     { title: 'inactive', path: PATH_DASHBOARD.subscription.inactiveList },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // // MANAGEMENT : Inactive Subscription
      // {
      //   title: 'Inactive Subscription',
      //   path: PATH_DASHBOARD.inactive_subscription.root,
      //   icon: ICONS.subscription,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.inactive_subscription.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : Information
      // {
      //   title: 'information',
      //   path: PATH_DASHBOARD.information.root,
      //   icon: ICONS.information,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'privacy policy', path: PATH_DASHBOARD.information.privacy_policy },
      //     { title: 'about us', path: PATH_DASHBOARD.information.about_us },
      //     { title: 'contact us', path: PATH_DASHBOARD.information.contact_us },
      //     { title: 'faq', path: PATH_DASHBOARD.information.faq },
      //     { title: 'terms & condition', path: PATH_DASHBOARD.information.terms_and_conditions },
      //     { title: 'General Security Information', path: PATH_DASHBOARD.information.general_security_information },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.edit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
